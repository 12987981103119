<template>
    <input class="form-control" :value="value" />
</template>
<script>
import $ from 'jquery';
import 'bootstrap-datepicker';
//import "bootstrap-datepicker/dist/css/boostrap-datepicker.css";
import '@/assets/custom.css';
export default {
    name: 'Datepicker',
    props: ['value', 'options'],
    mounted: function () {

        var self = this;
        var params = {format: 'dd/mm/yyyy', autoclose:true};
        if(this.options)params = this.options;
        //{format: 'dd/mm/yyyy', autoclose:true,startView: "months", minViewMode: "months"}
        $(self.$el)                    
            .datepicker(params) // init datepicker
            .trigger('change')                    
            .on('change', function () { // emit event on change.
                self.$emit('input', this.value);
            })
    },
    beforeDestroy: function() {
        $(this.$el).datepicker('hide').datepicker('destroy');
    },
    watch: {
        value: function(old, val) {
            $(this.$el).val(val);
        }
    }
}
</script>
<style scoped>
</style>